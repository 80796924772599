<script>
import EmptyList from './EmptyList'
import NewDatepicker from './NewDatepicker'
import SelectField from './SelectField'
import Loading from './Loading.vue'
export default {
  components: {
    SelectField,
    EmptyList,
    NewDatepicker,
    Loading
  },
  name: 'FilterableTable',
  props: {
    title: {
      type: String
    },
    headers: {
      type: Array
    },
    items: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    emptyBoxSuffix: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      isReady: false,
      filter: {
      }
    }
  },
  methods: {
    handleFilters () {
      this.$emit('setFilters', this.filter)
    },
    handleSetFilters (filter, date) {
      const [year, month, day] = date.split('-')
      this.filter[filter] = filter === 'startDate' ? `${year}-${month}-${day}` : `${year}-${month}-${Number(day) + 1}`
    },
    initialFormatDate (type) {
      const date = new Date()
      const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()]
      return type === 'from' ? `${year}-${month}-${day}` : `${year}-${month + 1}-${day + 1}`
    }
  },
  watch: {
    items () {
      this.isReady = true
    }
  },
  created () {
    this.filters.forEach(filter => {
      this.$set(this.filter, filter.key, filter.default || null)
    })
    this.filter = {
      startDate: this.initialFormatDate('from'),
      endDate: this.initialFormatDate('to'),
      orderList: 'all'
    }
    this.$emit('inicitialFilters', this.filter)
  }
}
</script>
<template>
  <div class="filterable-table--container">
    <h5 class="filterable-table--title">{{ title }}</h5>
    <div class="filterable-table--filter-wrapper" v-if="filters.length">
      <template v-for="(filterItem, i) in filters">
        <NewDatepicker
          v-if="filterItem.type === 'datepicker'"
          :key="i"
          :label="filterItem.label"
          v-model="filter[filterItem.key]"
          @input="(value) => handleSetFilters(filterItem.key, value)"
        />
        <select-field
          v-if="filterItem.type === 'selectfield'"
          :key="filterItem.key"
          v-model="filter[filterItem.key]"
          outlined
          :items="filterItem.items"
          dense
          hide-validation
          background-color="#fff"
        ></select-field>
      </template>
      <v-btn class="filterable-table--btn-submit" outlined color="rgba(0, 0, 0, 0.04)" @click="handleFilters()">
        <v-icon size="20px" color="#272730">mdi-magnify</v-icon>
      </v-btn>
    </div>
    <div class="filterable-table--table-wrapper">
      <Loading v-if="!isReady"/>
      <table cellspacing="0" cellpadding="0" v-if="!!isReady && items.length">
        <thead>
          <th v-for="header in headers" :key="header.key" :width="header.width">
            {{ header.title }}
          </th>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.title">
            <td v-for="header in headers" :key="item.title + header.key" :style="{color: header.customColor ? item.color : '#000'}">{{ item[header.key] }}</td>
          </tr>
        </tbody>
      </table>
      <empty-list
      v-else-if="!!isReady && !items.length"
      :title="$t(`filterable.table.empty.state.title:${emptyBoxSuffix}`)"
      :textOne="$t(`filterable.table.empty.state.description:${emptyBoxSuffix}`)"
      image="/assets/images/empty-box.png"
      ></empty-list>
    </div>
  </div>
</template>
<style lang="scss">
.filterable-table--container {
  margin-top: 40px;
  .filterable-table--title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #272730;
    margin-bottom: 8px;
  }
  .filterable-table--filter-wrapper {
    display: flex;
    margin-bottom: 8px;
    > * {
      margin-right: 16px;
    }
    .v-select {
      max-width: 270px;
    }
    .filterable-table--btn-submit {
      min-width: 36px;
      width: 36px;
    }
  }
  .filterable-table--table-wrapper {
    table {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.06);
      thead {
        background: #FAFAFA;
        th {
          background: #FAFAFA;
          padding: 16px 24px;
        }
      }
      tbody {
        tr {
          td {
            background: #FFFFFF;
            border-top: 1px solid rgba(0, 0, 0, 0.06);
            padding: 16px 24px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #272730;
          }
        }
      }
    }
  }
}
</style>
