<script>
export default {
  name: 'NewDatePicker',
  props: {
    value: {
      type: String
    },
    label: {
      type: String
    }
  },
  data () {
    return {
      date: null,
      dateFormatted: null,
      menu: false
    }
  },
  methods: {
    format (date) {
      const [year, month, day] = date.split('-')
      const [nowYear, nowMonth, nowDay] = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).split('-')
      if (`${day}/${month}/${year}` === `${Number(nowDay) + 1}/${nowMonth}/${nowYear}`) return `${nowDay}/${nowMonth}/${nowYear}`
      return `${day}/${month}/${year}`
    }
  },
  computed: {
    computedDateFormatted () {
      return this.format(this.date)
    }
  },
  watch: {
    date () {
      this.dateFormatted = this.format(this.date)
      this.$emit('input', this.date)
    }
  },
  created () {
    this.date = this.value
  }
}
</script>
<template>
  <div class="datepicker--container">
    <div class="datepicker--box datepicker--left-box">
      <span>{{ label }}</span>
    </div>
    <div class="datepicker--body">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            readonly
            outlined
            dense
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          min="1950-01-01"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="datepicker--box datepicker--right-box">
      <v-icon class="clickable" @click="menu = !menu">mdi-calendar</v-icon>
    </div>
  </div>
</template>
<style lang="scss">
.datepicker--container {
  display: inline-flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  .datepicker--box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F8F9FB;
    padding: 7px 16px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    &.datepicker--left-box {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
    &.datepicker--right-box {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }
  }
  .datepicker--body {
    .v-text-field--outlined {
      border-radius: 0 !important;
      width: 120px;
      fieldset {
        border-color: rgba(0, 0, 0, 0.06) !important;
      }
    }
  }
}
</style>
